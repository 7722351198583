import { template as template_fa1c6bc1a01344a2bc97aef50ed4eaba } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_fa1c6bc1a01344a2bc97aef50ed4eaba(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
