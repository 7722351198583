import { template as template_0c71cc4104c3413ca01acca242350f80 } from "@ember/template-compiler";
const FKLabel = template_0c71cc4104c3413ca01acca242350f80(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
