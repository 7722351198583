import { template as template_5725cde357c149e5b6e33b5645892a42 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5725cde357c149e5b6e33b5645892a42(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
