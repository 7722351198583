import { template as template_89e73d04463f4b80b4156a20a0ce343c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_89e73d04463f4b80b4156a20a0ce343c(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
